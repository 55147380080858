import React from 'react'
import Contact from '../components/ContactUs'

const CivilConstruction = () => {
document.title ='Civil Construction - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">Civil Construction</h3>
            <p className="my-6 text-paragraph text-sm">
            We have experienced professionals for the Construction of Well Locations, Building Works, Road Works and heavy machinery foundation in public and private sector as per ISO standards and the client satisfaction.
            </p>
            <p className="my-6 text-paragraph text-sm">
            We understand and promote the idea of working as a partnership with our clients to ensure their goals are met.
            </p>
            <p className="my-6 text-paragraph text-sm">
            Our network of construction resources facilitates the effective mobilization of skilled construction teams and advanced methodologies around the country.We pride ourselves on being skilled and experienced in all aspects of civil construction and this, combined with our extensive knowledge of the Oil & Gas sector, has established us as major players in this industry. We understand and promote the idea of working as a partnership with our clients to ensure their goals are met.
            </p>
            <p className="my-6 text-paragraph text-sm">
            KAMCON carefully considers quality, delivery, and total cost, and present the best technical solution.
            </p>
        </div>
      </div>
    </div>
  )
}

export default CivilConstruction
