import React from 'react'
import { Link } from 'react-router-dom';
import { FaClock } from "react-icons/fa";

const Card = (props) => {
  return (
    <div className='bg-white shadow-lg rounded overflow-hidden'>
      <img className='h-[220px] w-[390px]' src={props.image} alt={props.title} />
      <div className='p-4'>
        <Link to={props.url}><h1 className="text-2xl text-heading">{props.title}</h1></Link>
        <span className='text-heading text-[14px] font-medium my-2'>By kamcongeng</span>
        <p className='text-paragraph text-sm my-2'>{props.desc}</p>
        <div className='mt-6 flex justify-between items-center'>
            <p className='flex items-center gap-2 text-second font-medium text-sm'><FaClock /> {props.time}</p>
            <Link to={props.url} className='text-sm text-heading font-medium hover:text-primary'>Read more</Link>
        </div>
      </div>
    </div>
  )
}

export default Card
