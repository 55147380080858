import React from 'react'
import Contact from '../components/ContactUs'

const RentalServices = () => {
  document.title ='Rental Service For Equipments - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">Rental Services for Equipment</h3>
            <p className="my-6 text-paragraph text-sm">
            Kamcon have dynamic fleet of heavy lifting and earth moving machinery. The advantages of getting a rental machines eliminates capital investment, the cost of storage and maintenance. It also reduces the amount of project cost by choosing the right equipment for the required job only till its done.
            </p>
            <p className="my-6 text-paragraph text-sm">
            Our rental approach includes giving you the flexibility to exchange rented equipment over the lifecycle of your wells. As your field conditions change so will your equipment needs – & by working with KAMCON, you can make that happen on schedule and on budget.
            </p>
            <p className="my-6 text-paragraph text-sm">
            We are here to serve as your industry-specific rental service provider. When you choose KAMCON, you can be sure you’ll receive superior products and friendly, reliable service. Safety and compliance are top priorities at KAMCON.
            </p>
            
        </div>
      </div>
    </div>
  )
}

export default RentalServices
