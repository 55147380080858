import React from 'react'
import Contact from '../components/ContactUs'

const Procurement = () => {
  document.title ='Procurement - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">Procurement</h3>
            <p className="my-6 text-paragraph text-sm">
            The Procurement Department is staffed with experienced professionals capable of providing equipment, materials, and services to both large and small projects in a timely and cost effective manner. Purchasing, Expediting, Subcontracts, and Source Inspections are the functions performed by the KAMCON Procurement Department. Purchase Order are placed with global suppliers that are qualified to furnish materials and equipment for the Refining, Petrochemical, Power, Oil& Gas industries. KAMCON efforts to develop long term relationships with key suppliers, to make it a significant benefit for our Clients.
            </p>
            <p className="my-6 text-paragraph text-sm">
            Our procurement department manages equipment, materials, and services through strategic sourcing, material management, contract management and logistics functions. KAMCON advanced sourcing methods, market intelligence and global purchasing volume enable the company to drive cost and schedule certainty for Clients’ capital projects.
            </p>
            <p className="my-6 text-paragraph text-sm">
            KAMCON expertise is the timely procurement of materials and equipment for their projects. We have built a global network of trusted suppliers to meet Client objectives, delivering critical items to projects in a timely manner to meet project schedule and cost requirements.  We offer reliable project deliveries, innovative performance solutions and project savings through the combination of a global execution platform, industry-leading technologies and goods and services.
            </p>
            <p className="my-6 text-paragraph text-sm">
            KAMCON carefully considers quality, delivery, and total cost, and present the best technical solution.
            </p>
        </div>
      </div>
    </div>
  )
}

export default Procurement
