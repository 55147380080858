import React from 'react'
import Contact from '../components/ContactUs'

const EIWork = () => {
  document.title ='E & I Work - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">E & I Work</h3>
            <p className="my-6 text-paragraph text-sm">
            KAMCON can deliver complex, logistically challenging projects anywhere in the country. With qualified and skilled workforce deployed on projects across the country and 24/7 project-execution capabilities, we deliver projects with the quality and safety our Clients expect.
            </p>
            <p className="my-6 text-paragraph text-sm">
            We offering our services for our value able client in safe manner to ensure minimal operational disruption, the services include:-
            </p>
            <ul className='text-paragraph text-sm list-disc mx-4'>
                <li>High Current Electrical Systems</li>
                <li>Low Current Electrical Systems</li>
                <li>Virtual Instrumentation</li>
                <li>Instrumentation</li>
                <li>Process Control Systems</li>
                <li>Fire & Gas, Security & CCTV Systems</li>
                <li>All electrical activities in hazardous areas</li>
                <li>Metrology & calibration</li>
 
            </ul>
        </div>
      </div>
    </div>
  )
}

export default EIWork
