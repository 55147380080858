import React from 'react'
import 'react-slideshow-image/dist/styles.css';
import { Zoom, Fade, Slide } from 'react-slideshow-image';
import slider1 from '../assets/Company/Slide-Careers-1.jpg'
import slider2 from '../assets/Company/Slide-Careers-2 (1).jpg'
import slider3 from '../assets/Company/Slide-Careers-2.jpg'
import slider4 from '../assets/Company/Slide-Careers-4.jpg'




const Company = () => {
  document.title = 'Company';

  const slideImages = [
    {
      url: slider1,
    },
    {
      url: slider2,
    },
    {
      url: slider3,
    },
    {
      url: slider4,
    },
  ];

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '520px',
    backgroundSize: 'cover',
    backgroundColor: '#000000'
  }



  return (
    <div className='z-[1] max-w-[1200px] mx-auto'>
      <div className="pt-28">
        <Fade>
          {slideImages.map((image, index) => (
            <div key={index}>
              <div className='bg-cover' style={{ ...divStyle, backgroundImage: `url(${image.url})` }}>
                <div className='text-center flex flex-col justify-center items-center uppercase'>
                </div>
              </div>
            </div>
          ))}
        </Fade>
      </div>
      <div className='my-12 mx-4'>
        <h1 className="text-3xl font-light text-heading">Who We Are</h1>
        <p className='text-paragraph my-6 font-normal'>We emphasize on team work for the provision of excellent services and to achieve the targeted goals on time by planning, managing and controlling all aspects of projects.</p>
        <p className='text-paragraph my-6 font-normal'>Our company have competent team of multi-disciplined professional including the Engineers, Supervisors and Foremen backed by the latest equipment, machinery and tools.</p>
        <p className='text-paragraph my-6 font-normal'>We followed a steady path of growth in the field of Engineering, Procurement, Construction & Plant turnaround with the most efficient time saving and cost effective approach.</p>
      </div>
      <hr />
      <div className="py-9 mx-4">
        <h2 className="text-3xl font-light text-heading">History</h2>
        <div className="lg:flex justify-between my-4">
          <div className='md:flex gap-4 lg:w-[700px]'>
            <img className='w-[180px] h-[180px]' src="http://kamconeng.com/wp-content/uploads/2019/12/Office-1.jpg" alt="" />
            <p className='text-sm text-paragraph'>Mr. Muhammad Yaseen (C.E.O. of Kamcon Engineering (Pvt) Limited) previously worked with M/s Pak Arab Engineering (Pvt) Limited and Pak Arab Engineering Company since 2002  in capacity of Director Operations and holding 50% shares of both companies. After the business separation from Pak Arab Engineering (Pvt) Limited in 2019 introduced his own company namely Kamcon Engineering (Pvt) Limited.</p>
          </div>
          <div>
            <ul>
              <li className='border-b py-4 text-paragraph'><b className='text-primary'>2002 - </b> 50% Share Holder Pak Arab Engineering</li>
              <li className='border-b py-4 text-paragraph'><b className='text-primary'>2019 – </b> Kamcon Engineering formed</li>
              <li className='border-b py-4 text-paragraph'><b className='text-primary'>2019 – </b> Sadiqabad Workshop opened</li>
              <li className='py-4 text-paragraph'><b className='text-primary'>2019 – </b> Kamcon Head Office opened</li>
            </ul>
          </div>
        </div>
      </div><hr />
      <div className='py-9'>
        <div className="md:flex gap-4 justify-between">
          <div className='text-center text-sm text-paragraph'>
            <h3 className="text-3xl text-center text-heading">Mission</h3>
            <p className='my-2'>To perform for our customers the highest level of quality construction services at fair and market competitive prices.</p>
            <p className='my-2'>To ensure the longevity of our company through repeat and referral business achieved by customer satisfaction in all areas including timeliness, attention to detail and service-minded attitudes.</p>
            <p className='my-2'>To maintain the highest levels of professionalism, integrity, honesty and fairness in our relationships with our suppliers, subcontractors, professional associates and customers.</p>
          </div>
          <div className='text-center text-sm text-paragraph'>
            <h3 className="text-3xl text-center text-heading">Vision</h3>
            <p className='my-2'>Our mission is to provide our employees with an honest and helpful working environment, where every employee individually and collectively, can dedicate themselves to providing our customers with exceptional workmanship, extraordinary service, and professional integrity.</p>
            <p className='my-2'>Our commitment to this mission will allow Kamcon Engineering (Pvt) Limited to become not only a premier construction company, but the premier construction company in World wide.</p>
          </div>
        </div>
      </div><hr />
      <div className='py-9 mx-4'>
        <h3 className="text-3xl text-heading">Leadership</h3>
        <div className="md:flex justify-between my-4 items-center">
          <div className='flex gap-4'>
            <img className='w-[180px]' src="http://kamconeng.com/wp-content/uploads/2019/12/Yaseen-Head-Shot.jpg" alt="" />
            <div className='my-4'>
              <h1 className="text-2xl font-semibold text-heading">Muhammad Yaseen</h1>
              <strong className='font-medium'>C.E.O</strong>
            </div>
          </div>
          {/* <div className='flex gap-4'>
            <img className='w-[180px]' src="http://kamconeng.com/wp-content/uploads/2019/12/Mazhar-Bhai-Head-Shot2.jpg  " alt="" />
            <div className='my-4'>
              <h1 className="text-2xl font-semibold text-heading">Mazhar Bukhari</h1>
              <strong className='font-medium'>GM Operations</strong>
            </div>
          </div> */}
        </div>
      </div><hr />
    </div>
  );
}

export default Company
