import React from 'react'
import footerImg from '../assets/footer-construction.jpg'
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { IoSend } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";


const Footer = () => {
  return (
    <div className='lg:h-[480px] bg-cover no-repeat' style={{backgroundImage:`url(${footerImg})`}}>
      <div className="max-w-[1200px] lg:mx-auto mx-4">
        <div className="lg:flex gap-28 pt-[10rem]">
            <div className='my-12'>
                <Link><img className='lg:w-auto w-[120px]' src={logo} alt="" /></Link>
            </div>
            <div>
                <h4 className="text-3xl text-heading uppercase font-light">Navigation</h4>
                <ul className='my-4 flex flex-col gap-4 text-paragraph text-[14px] font-medium'>
                    <li className='hover:text-primary'><Link to='/'>Home</Link></li>
                    <li className='hover:text-primary'><Link to='/company'>Company</Link></li>
                    <li className='hover:text-primary'><Link to='/services'>Services</Link></li>
                    <li className='hover:text-primary'><Link to='/careers'>Careers</Link></li>
                </ul>
            </div>
            <div className='my-12'>
                <ul className=' flex flex-col gap-4 text-paragraph text-[14px] font-medium'>
                    <li className='hover:text-primary'><Link to='projects'>Projects</Link></li>
                    <li className='hover:text-primary'><Link to='news'>News</Link></li>
                    <li className='hover:text-primary'><Link to='contact'>Contact</Link></li>
                    <li className='hover:text-primary'><Link to='http://kamconeng.com/wp-content/uploads/2019/12/Kamcon-Profile.pdf'>Profile</Link></li>
                </ul>
            </div>
            <div>
                <h4 className="text-3xl text-heading uppercase font-light">Newsletter</h4>
                <div className='lg:flex items-center my-4'>
                <input type="text" placeholder='Email Adress' className='outline-none border border-[#ccc] py-2 px-4 lg:w-[400px] mx-auto text-paragraph text-sm'/>
                <button className='bg-white py-3 border px-3 cursor-pointer hover:bg-[rgba(0,0,0,0.1)]'><IoSend /></button>
                </div>
                <div className='flex justify-between items-center'>
                    <p className='flex gap-2 text-sm'><FaPhoneAlt /> (+92)319 9088001</p>
                    <p className='flex gap-2 text-sm'><IoIosMail /> info@kamconeng.com</p>
                </div>
            </div>
        </div>
      </div>
        <div className='border-t-2 border-white py-9 text-center text-sm text-paragraph'>
          <span>Kamcon Engineering (Pvt) Ltd © Copyright 2019. All Rights Reserved. | Developed By <Link className='text-second' target='_blank' to='https://www.kamconeng.com'>MIS Department</Link></span>
        </div>
    </div>
  )
}

export default Footer
