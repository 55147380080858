import React, { useEffect } from 'react'
import {Link } from 'react-router-dom';
import {CiLocationArrow1} from 'react-icons/ci'
import AOS from 'aos';
import 'aos/dist/aos.css';


const ServicesCard = (props) => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='mb-9' data-aos="fade-right">
      <div className='flex gap-6'>
        <img src={props.image} alt=""  className='w-[3.3rem] h-full'/>
        <div>
            <h4 className="text-xl text-heading">{props.title}</h4>
            <p className='my-3 text-sm text-paragraph'>{props.para}</p>
            <Link className='text-primary flex items-center' to={props.url}>Read More <CiLocationArrow1 className='mx-1 text-lg'/></Link>
        </div>
      </div>
    </div>
  )
}

export default ServicesCard
