import React, { useState } from 'react';
import logo from '../assets/logo.png'
import { Link, NavLink } from 'react-router-dom';
import {AiOutlineMenu,AiOutlineClose} from 'react-icons/ai'
const Header = () => {
  const menuItems = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Company',
      url: '/company'
    },
    {
      title: 'Services',
      url: '/services',
      submenu: [
        {
          title: 'Procurement',
          url: '/services/procurement'
        },
        {
          title: 'Civil Construction',
          url: '/services/civil-construction'
        },
        {
          title: 'Mechanical Fabrication and Erection',
          url: '/services/mechanical-fabrication-erection'
        },
        {
          title: 'E & I Work',
          url: '/services/e-i-work'
        },
        {
          title: 'Flow Line',
          url: '/services/flow-line'
        },
        {
          title: 'Plant Turnaround',
          url: '/services/plant-turnaround'
        },
        {
          title: 'Manpower Supply',
          url: '/services/manpower-supply'
        },
        {
          title: 'Rental Service for Equipments',
          url: '/services/rental-services-for-equipment'
        },
      ]
    },
    {
      title: 'Projects',
      url: '/projects'
    },
    {
      title: 'News',
      url: '/news'
    },
    {
      title: 'Careers',
      url: '/careers'
    },
    {
      title: 'Contact',
      url: '/contact'
    },
    {
      title: 'Profile',
      url: 'http://kamconeng.com/wp-content/uploads/2019/12/Kamcon-Profile.pdf'
    },
  ]

  const [isOpen,setIsOpen] = useState(false);
  const [navOpen,setnavOpen] = useState(false);
  // const [iconNav,setIconNav] = useState(false);

  const handleClick = ()=>{
    setIsOpen(!isOpen);
  }
  return (
    <>
    <div className='bg-white/90 lg:py-0 py-4 fixed w-full z-[999999]'>
      <div className="max-w-[1200px] mx-auto">
        <div className="flex justify-between items-center lg:mx-0 mx-4">
          <div className='w-[160px]'>
            <img src={logo} alt="" />
          </div>

          <div>
            <ul className='lg:flex hidden items-center'>
              {menuItems.map(menu => {
                return (
                  <li className='hover:bg-primary hover:text-white font-bold text-lightdark uppercase text-sm'>
                    {menu.submenu ? (
                    <>
                      <NavLink to={menu.url} className='peer px-2 hover:bg-primary py-6 hover:text-white' type="button" aria-haspopup="menu">
                        {menu.title}{' '} <i className='fa fa-caret-down'></i>
                      </NavLink>
                      <ul className="dropdown absolute hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg border-t-2 border-second">
                        {menu.submenu.map((submenu, index) => (
                          <li key={index} className="menu-items hover:bg-slate-100 py-2 px-4 border-b font-normal">
                            <Link className='text-[#777777]' to={submenu.url}>{submenu.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (<NavLink className='py-6 px-2 hover:bg-primary hover:text-white' to={menu.url}>{menu.title}</NavLink>)}</li>
                )
              })}
            </ul>
            {navOpen && (
              <ul className='z-[999999] bg-white items-center absolute right-0 top-[4.4rem] sm:w-[500px] w-full text-center shadow-lg py-2'>
              {menuItems.map(menu => {
                return (
                  <li className='font-bold text-lightdark uppercase text-sm py-2 px-9'>
                    {menu.submenu ? (
                    <>
                      <NavLink to={menu.url} className='peer ' onClick={handleClick}>
                        {menu.title}{' '} <i className='fa fa-caret-down'></i>
                      </NavLink>
                      {isOpen && (
                        <ul className="dropdown  hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg border-t-2 border-second">
                        {menu.submenu.map((submenu, index) => (
                          <li key={index} className="menu-items hover:bg-slate-100 py-2 px-4 border-b font-normal">
                            <Link className='text-[#777777]' to={submenu.url}>{submenu.title}</Link>
                          </li>
                        ))}
                      </ul>
                      )}
                    </>
                  ) : (<NavLink className='' to={menu.url}>{menu.title}</NavLink>)}</li>
                )
              })}
            </ul>
            )}
            <button onClick={()=> setnavOpen(!navOpen)} className='lg:hidden bg-primary px-2 py-2 rounded text-white text-xl'>
              {navOpen ? (
                <AiOutlineClose />
                ) :
                <AiOutlineMenu />
            }
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Header
