import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const Projects = () => {
  const [pdata,setpData] = useState([]);
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    axios.get('https://kamconeng.com/admin/api/project-api')
      .then(response => {
        setpData(response.data.slice(0,6));
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className='bg-white py-12'>
      <div className="max-w-[1200px] lg:mx-auto mx-4">
        <h1 className="text-3xl text-heading">Our Projects</h1>
        <span className='text-paragraph'>Highlights our successfully completed projects.</span>
        <div className="grid grid-cols-3 gap-4 mt-6" data-aos="fade-right">
          {
            pdata.map((proj,index)=>{
              return(
                <div key={index}>
                  <div className='overflow-hidden'><Link to={`/project/${proj.project_id}/${proj.project_title}`} ><img className='z-[1] relative duration-300 w-[400px] cursor-pointer hover:scale-110' src={`https://kamconeng.com/admin/upload/${proj.image}`} alt="" /></Link>
                </div>
                <div className="top-0 z-[9999] overflow-hidden font-bold"><Link to={`/project/${proj.project_id}/${proj.project_title}`}>{proj.project_title}</Link></div>
                </div>
              )
            })
          }
        </div>
        <Link to='/projects' className='duration-300 border-2 border-primary  flex justify-center hover:bg-primary my-4 py-2 text-primary hover:text-white font-semibold'>View All</Link>
      </div>
    </div>
  )
}

export default Projects
