import React from 'react'

const Contact = () => {
  return (
    <div>
      <div className='lg:w-[300px]'>
            <h3 className="text-xl">Contact Us</h3>
            <p className='text-paragraph text-sm my-2'>Contact us or give us a call to discover how we can help.</p>
            <div className='mt-6'>
                <div>
                    <span className='text-sm text-paragraph'>Your Name *</span>
                    <input type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full'/>
                </div>
                <div className='my-6'>
                    <span className='text-sm text-paragraph'>Your email address *</span>
                    <input type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full'/>
                </div>
                <div className='my-6'>
                    <span className='text-sm text-paragraph'>Subject</span>
                    <input type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full'/>
                </div>
                <div className='my-6'>
                    <span className='text-sm text-paragraph'>Message *</span>
                    <textarea type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full'></textarea>
                </div>
                <button className='py-2 px-6 bg-primary text-white text-sm'>Send Message</button>
            </div>
        </div>
    </div>
  )
}

export default Contact
