import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import dotline from '../../../assets/dotted-line-angle.png'
import {CiLocationArrow1} from 'react-icons/ci'
import AOS from 'aos';
import 'aos/dist/aos.css';

const WhoWeAre = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='bg-white py-[4rem]'>
      <div className="max-w-[1200px] lg:mx-auto mx-4">
        <div className="md:flex justify-between">
          <div className='' data-aos="fade-right">
            <h1 className="text-3xl text-heading font-light">Who We Are</h1>
            <span className='text-paragraph'>Small enough to listen.</span><br />
            <span className='text-paragraph'>Big enough to Deliver!</span>
          </div>
          <img className='md:block hidden' src={dotline} alt="" />
          <div className='md:my-0 my-4 md:w-[500px] text-paragraph text-[0.9rem]' data-aos="fade-left">
            <p >We emphasize on team work for the provision of excellent services and to achieve the targeted goals on time by planning, managing and controlling all aspects of projects.</p>
            <p className='my-4'>Our company have competent team of multi-disciplined professional including the Engineers, Supervisors and Foremen backed by the latest equipment, machinery and tools.</p>
            <Link className='text-primary flex items-center' to='/company'>Read More <CiLocationArrow1 className='mx-1 text-lg'/></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhoWeAre
