import React, { useEffect } from 'react'
import message from '../../../assets/Home-Screen-Web-1.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Message = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div className='mb-9 max-w-[1200px] lg:mx-auto mx-4' data-aos='fade-left'>
      <div className='md:flex justify-center lg:gap-16 gap-1 items-center'>
        <img className='lg:w-[300px]' src={message} alt="" />
        <div className='my-4'>
            <strong className='text-2xl text-heading'>C.E.O Message</strong>
            <p className='text-paragraph text-xl my-2'>Kamcon Engineering (Pvt) Limited (Formerly Pak Arab Engineering (Pvt) Limited) provide services in Engineering, Procurement, Construction and Manpower & Equipment supply activities. Our strength is vested on experienced, competent, committed and dedicated team and large inventory of latest equipment and machinery.</p>
            <strong>Mr. Muhammad Yaseen</strong><br />
            <span>C.E.O - Kamcon Engineering</span>
        </div>
      </div>
    </div>
  )
}

export default Message
