import React from 'react'
import { FaLocationDot  } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
const Contact = () => {
    document.title ='Contact Us' ;
    return (
        <div className='py-16'>
            <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Contact</div>
            <div className="max-w-[1200px] lg:mx-auto mx-4 my-9">
                <div className="lg:flex gap-16">
                    <div>
                        <div className='lg:w-[600px]'>
                            <h3 className="text-3xl">Send Us a Message</h3>
                            <p className='text-paragraph my-2'>Contact us or give us a call to discover how we can help.</p>
                            <div className='mt-6'>
                                <div>
                                    <span className='text-sm text-paragraph'>Your Name *</span>
                                    <input type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full' />
                                </div>
                                <div className='my-6'>
                                    <span className='text-sm text-paragraph'>Your email address *</span>
                                    <input type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full' />
                                </div>
                                <div className='my-6'>
                                    <span className='text-sm text-paragraph'>Subject</span>
                                    <input type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full' />
                                </div>
                                <div className='my-6'>
                                    <span className='text-sm text-paragraph'>Message *</span>
                                    <textarea type="text" className='text-sm text-paragraph outline-none border py-2 px-4 w-full'></textarea>
                                </div>
                                <button className='py-2 px-6 bg-primary text-white text-sm'>Send Message</button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-0 lg:my-0 my-9'>
                        <div>
                            <h4 className="text-3xl mb-3">Corporate Office</h4>
                            <div className='lg:w-[450px] lg:h-[250px]'>
                                <iframe className='lg:w-[450px]' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.1643252619238!2d67.06364821534316!3d24.858236634055082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f1452c63fa9%3A0x3003ef8a8b053975!2sKamcon%20Engineering%20Pvt%20Limited!5e0!3m2!1sen!2s!4v1576666184054!5m2!1sen!2s" width="450" height="250" frameborder="0" allowfullscreen="true"></iframe>
                            </div>
                        </div>
                        <div className='my-8'>
                            <h4 className="text-3xl mb-3">Sadiqabad Office / Workshop</h4>
                            <div className='lg:w-[450px] lg:h-[250px]'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3511.997158774721!2d70.13150721540607!3d28.32868768253858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3937418fa2a286cf%3A0xe221d6e67f4923d7!2sKAMCON%20ENGINEERING%20COMPANY!5e0!3m2!1sen!2s!4v1576143293914!5m2!1sen!2s" width="450" height="250" frameborder="0" allowfullscreen=""></iframe>
                            </div>
                        </div>
                        <div className='my-8 text-sm text-paragraph'>
                            <div className="flex items-center gap-2 text-second text-sm"><FaLocationDot /> <span className='text-paragraph font-bold'>Address :</span></div>
                            <div className='my-4 mx-6'>
                                <p><b>Head Office: </b> First Floor, Plot # 1/6-M, Block-6 PECHS Society, Nursery, Karachi</p>
                                <p><b>Sadiqabad Office: </b> 2 KM – KLP Road Near Industrial State, Sadiqabad District, Rahim Yar Khan</p>
                            </div>
                            <div className="flex items-center gap-2 text-second text-sm"><FaPhoneAlt /> <span className='text-paragraph font-bold'>Phone :</span></div>
                            <div className='my-4 mx-6'>
                                <p><b>Phone: </b> +92 319 9088001</p>
                                
                            </div>
                            <div className="flex items-center gap-2 text-second text-sm"><FaLocationDot /> <span className='text-paragraph font-bold'>E-mail :</span></div>
                            <div className='my-4 mx-6'>
                                info@kamconeng.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
