import React from 'react'
import Contact from '../components/ContactUs'

const PlantTurn = () => {
  document.title ='Plant Turnaround - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">Plant Turnaround</h3>
            <p className="my-6 text-paragraph text-sm">
            KAMCON offers a team of highly trained specialists to manage operate and maintain our customers’ Plant. Our specialists care for all equipment as if it were property of KAMCON – with dedication and enthusiasm. KAMCON offers operations and maintenance solutions that increase our customers’ production uptime.
            </p>
            <p className="my-6 text-paragraph text-sm">
            Maintenance is a critical stage in the life of any facility. The process may be thought of as a series of vital checks that are undertaken before actual operation begins. Our complete maintenance services can play an important role in minimizing project risks and achieving performance goals right from the start. By completing equipment maintenance schedules on time, we minimize unscheduled repairs and breakdowns, thereby reducing overall operations cost.
            </p>
            <p className="my-6 text-paragraph text-sm">
            KAMCON also has an experienced support staff that is second to none, working directly with manufacturers to troubleshoot engineering and fabrication flaws or ongoing equipment problems. We can put together an operations or maintenance solution for most any type of natural gas or oil process application anywhere in Pakistan. We can also track maintenance and inventory with sophisticated, advanced software and communications technology. Applications we regularly serve include:
            </p>
            <ul className='text-paragraph text-sm list-disc mx-4'>
                <li>Reciprocal Compression</li>
                <li>Rotary Compression</li>
                <li>Turbines</li>
                <li>Vessels</li>
                <li>Heat Exchangers</li>
                <li>Compression Stations</li>
                <li>Power Generation</li>
                <li>Process Plants</li>
                <li>Production Equipment</li>
                <li>Wellheads</li>
                <li>HRSG</li>
                <li>Storage Tanks</li>
                <li>Remote Facilities</li>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default PlantTurn
