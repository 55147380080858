import React from 'react'
import Contact from '../components/ContactUs'

const FlowLine = () => {
  document.title ='Flow Line - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">Flow Line  </h3>
            <p className="my-6 text-paragraph text-sm">
            KAMCON Engineering is determined to become Pakistan’s premier Flow Line construction and maintenance company serving the Industry throughout Pakistan. We pride ourselves on our ability to complete our Flow Line construction projects in a manner consistent with the utmost regard to environmental protection and employee safety.
            </p>
            <p className="my-6 text-paragraph text-sm">
            KAMCON Engineering Services (Pvt.) Limited have latest equipment and machinery for the laying of low and high pressure flow lines In Pakistan as per the ISO & OHSAS Standard like:
            </p>
            <ul className='text-paragraph text-sm list-disc mx-4'>
                <li>Oil & Gas Cross Country Flow line</li>
                <li>Water Injection Lines</li>
                <li>Water Transmission Lines</li>
            </ul>
            <p className="my-6 text-paragraph text-sm">
            Today, our reputation for safety, experienced personnel, quality, efficiency and jobs completed on schedule and under budget is second to none.
            </p>
        </div>
      </div>
    </div>
  )
}

export default FlowLine
