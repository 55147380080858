import React from 'react'
import 'react-slideshow-image/dist/styles.css';
import { Zoom,Fade,Slide } from 'react-slideshow-image';
import slider1 from '../../../assets/Slider-Image-1.jpg'
import slider2 from '../../../assets/Slider-Image-3.jpg'
import slider3 from '../../../assets/Slider-Image-6.jpg'
import slider4 from '../../../assets/Slider-Image-7.jpg'




const HeroSection = () => {

  const slideImages = [
    {
      url:slider1,
      caption:'From Concept to Creation',
    },
    {
      url:slider2,
      caption:'Engineering & Construction',
    },
    {
      url:slider3,
      caption:'Oil & Gas Plant Construction',
    },
    {
      url:slider4,
      caption:'Chemical Plant Construction & Maintenance',
    },
  ];

  const divStyle = {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    height:'920px',
    backgroundSize:'cover',
    backgroundColor:'#000000'
  }



  return (
    <div className='slide-container z-[1]'>
      <Slide>
        {slideImages.map((image,index)=>(
          <div key={index}>
            <div className='bg-cover' style={{...divStyle,backgroundImage:`url(${image.url})`}}>
              <div className='text-center flex flex-col justify-center items-center uppercase'>
                <span className='bg-black/60 text-white text-xl px-4 py-1'>-Kamcon-</span>
                <span className='text-white font-bold md:text-[5rem] sm:text-[3rem] text-2xl w-auto md:w-[700px] my-2 sm:leading-[5rem] block text-center bg-black/60'>{image.caption}</span>
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
}

export default HeroSection
