import React, { useEffect, useState } from 'react'
import Card from '../../Card'
import newsimage1 from '../../../assets/News/blog-construction-1.jpg'
import newsimage2 from '../../../assets/News/blog-construction-2.jpg'
import newsimage3 from '../../../assets/News/construction3.jpeg'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const NewsEvents = () => {
  const [data,setData] = useState([]);
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    axios.get('https://kamconeng.com/admin/api/blog-api.php')
      .then(response => {



        setData(response.data.slice(0,3));
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, []);

  const convertHtmlToText = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };



  return (
    <div className='bg-white py-9'>
      <div className="max-w-[1200px] lg:mx-auto mx-4">
        <h1 className="text-3xl text-heading">News & Events</h1>
        <span className='text-paragraph'>Overview of our breakthroughs!</span>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4 my-6" data-aos='fade-right'>
          {
            data.map((blog)=>{
              return(
                <Card
                title={blog.post_title}
                desc={`${convertHtmlToText(blog.post_description.slice(0,100))}...`}
                image={`https://kamconeng.com/admin/upload/${blog.post_image}`}
                url={`blog/${blog.id}/${blog.post_title}`}
                time={blog.post_date.slice(0,10)}
                />
              )
            })
          }

            {/* <Card
            title=''
            image={newsimage3}
            url=''
            time='25 July'
            />
            <Card
            title='Successful Pre-Qualification'
            image={newsimage2}
            url=''
            time='3 December'
            /> */}
        </div>
        <Link className='duration-300 flex justify-center my-16 border-2 border-primary text-primary font-semibold hover:bg-primary hover:text-white py-2 '>View All</Link>
      </div>
    </div>
  )
}

export default NewsEvents
