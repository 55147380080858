import React, { useEffect, useState } from 'react'
import NewsEvents from '../components/pageSections/HomePage/NewsEvents'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Card from '../components/Card';


const News = () => {
  document.title ='News' ;

    const [data,setData] = useState([]);


  useEffect(() => {
    axios.get('https://kamconeng.com/admin/api/blog-api.php')
      .then(response => {



        setData(response.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, []);

  const convertHtmlToText = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  


  return (
    <div className='bg-white py-28'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase mb-9">News</div>
      <div className="max-w-[1200px] lg:mx-auto mx-4">
        <h1 className="text-3xl text-heading">News & Events</h1>
        <span className='text-paragraph'>Overview of our breakthroughs!</span>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4 my-6" >
          {
            data.map((blog)=>{
              return(
                <Card
                title={blog.post_title}
                desc={`${convertHtmlToText(blog.post_description.slice(0,100))}...`}
                image={`https://kamconeng.com/admin/upload/${blog.post_image}`}
                url={`/blog/${blog.id}/${blog.post_title}`}
                time={blog.post_date.slice(0,10)}
                />
              )
            })
          }

            {/* <Card
            title=''
            image={newsimage3}
            url=''
            time='25 July'
            />
            <Card
            title='Successful Pre-Qualification'
            image={newsimage2}
            url=''
            time='3 December'
            /> */}
        </div>
      </div>
    </div>
  )
}

export default News
