import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { CiUser,CiFolderOn,CiClock1   } from "react-icons/ci";

const SingleBlog = () => {
  const [data,setData] = useState([]);

  const { id } = useParams();
  console.log(id)
  useEffect(() => {
    axios.get(`https://kamconeng.com/admin/api/blog-single-api?id=${id}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, []);

  const convertHtmlToText = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  data.map((blog)=>{
    {document.title = blog.post_title}
  })
  

  return (
    <div className='py-28'>
      <div className='max-w-[1200px] mx-auto'>
        {
          data.map((blog)=>{
            return(
              <>
              <div className=''>
                
                <img className='w-[1000px] h-[500px]' src={`https://kamconeng.com/admin/upload/${blog.post_image}`} alt="" />
              </div>
              <div className='my-4'>
                <h1 className="text-3xl font-bold text-primary">{blog.post_title}</h1>
                <div className='flex gap-6'>
                  <div className='flex items-center gap-2 my-2'>
                    <CiUser />
                    <span className='text-sm text-paragraph'>By <Link className='text-primary' to='/news'>Kamconeng</Link></span>
                  </div>
                  <div className='flex items-center gap-2 my-2'>
                    <CiFolderOn  />
                    <span className='text-sm text-paragraph'><Link className='text-primary' to='/news'>News</Link></span>
                  </div>
                  <div className='flex items-center gap-2 my-2'>
                    <CiClock1   />
                    <span className='text-sm text-paragraph'>{blog.post_date.slice(0,10)}</span>
                  </div>
                </div>
                <p className='my-2 text-paragraph text-[14px]'>{convertHtmlToText(blog.post_description)}</p>
              </div>
              </>
            )
          })
        }
      </div>
    </div>
  )
}

export default SingleBlog
