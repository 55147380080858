import React from 'react'
import Contact from '../components/ContactUs'

const MechanicalFab = () => {
  document.title ='Mechanical Fabrication And Erection - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">Mechanical Fabrication and Erection</h3>
            <p className="my-6 text-paragraph text-sm">
            Kamcon technical experts constantly look for ways to streamline project delivery methods. Mechanical Fabrication and Erection are ways we can improve capital efficiencies for our Clients.
            </p>
            <p className="my-6 text-paragraph text-sm">
            We provide a broad range of field construction services that include:
            </p>
            <ul className='text-paragraph text-sm list-decimal mx-4'>
                <li>Fabrication, Erection & Installation.</li>
                <li>Steel Structure</li>
                <li>Storage Tanks</li>
                <li>Non “U” Stamp Vessels</li>
                <li>Plant Piping (CS, Duplex, Alloy & SS)</li>
                <li>Equipment Installation</li>
            </ul>
            <p className="my-6 text-paragraph text-sm">
            In addition to new construction work, we also do plant relocations, disassembly, upgrades, and shutdowns. Our resources are highly developed and are supported by a fleet of cranes and vehicles and an extensive range of welding, rigging, mechanical and piping equipment and facilities. We have an extensive range of general and specific procedures, work instructions and check sheets to support construction execution. Our extensive range of qualified welding procedures coupled with stringent weld control ensures optimum weld quality.
            </p>
        </div>
      </div>
    </div>
  )
}

export default MechanicalFab
