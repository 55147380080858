import React from 'react'
import ServicesCard from '../ServicesCard'
import procurement from '../../../assets/ground-construction.png';
import mechanical from '../../../assets/Mechanical-Icon.png';
import electrical from '../../../assets/Electrical.png';
import manpower from '../../../assets/Man-Power-Icon.png';

import construction from '../../../assets/construction.png';
import flowline from '../../../assets/605867-200.png';
import plant from '../../../assets/oil-refinery.png';
import rental from '../../../assets/img_313886.png';


const Services = () => {
  return (
    <div className='bg-sectionsbg py-16'>
      <div className="max-w-[1200px] lg:mx-auto mx-4">
        <h1 className="text-3xl text-heading font-light">Services</h1>
        <span className='text-paragraph'>A Reliable name in Construction and Shut Down work</span>

        <div className="md:flex gap-4 justify-between my-9">
            <div className='md:w-[600px]'>
                <ServicesCard
                title='Procurement'
                para='Kamcon has experienced professionals capable of providing equipment, materials, and services to both large and small projects in a timely and cost effective manner.'
                url='/services/procurement'
                image={procurement}
                />
                <ServicesCard
                title='Mechanical Fabrication and Erection'
                para='Kamcon technical experts constantly look for ways to streamline project delivery methods.  Mechanical Fabrication and Erection are ways we can improve capital efficiencies for our Clients, regardless of industry.'
                url='/services/mechanical-fabrication-erection'
                image={mechanical}
                />
                <ServicesCard
                title='E & I Works'
                para='Kamcon have diverse experience and capabilities to perform electrical and instrumentation installation works to suit the best Client satisfaction.'
                url='/services/e-i-work'
                image={electrical}
                />
                <ServicesCard
                title='Manpower Supply'
                para='We are one of the known organization engaged in providing Engineer Manpower Service. Our organization is very experience to fulfill the requirement of our clients. Widely appreciated by our customers, these services are provided by our experienced team members who are perfect in this field.'
                url='/services/manpower-supply'
                image={manpower}
                />
            </div>
            <div className='md:w-[600px]'>
                <ServicesCard
                title='Civil Construction'
                para='Construction of Well Locations, Building Works, Road Works and heavy machinery foundation in public and private sector as per ISO standards.'
                url='civil-construction'
                image={construction}
                />
                <ServicesCard
                title='Flow line'
                para='Kamcon have the latest equipment and machinery for the laying of low and high pressure flow lines In Pakistan as per the ISO Standards'
                url='flow-line'
                image={flowline}
                />
                <ServicesCard
                title='Plant Turnaround'
                para='Specialist in Plant Turnaround in Oil & Gas, Petrochemical sector & Cement Plants'
                url='plant-turnaround'
                image={plant}
                />
                <ServicesCard
                title='Rental Services for Equipment'
                para='Kamcon have dynamic fleet of heavy earth moving machinery. The advantages of getting a rental machines eliminates capital investment, the cost of storage and maintenance. It also reduces the amount of project cost by choosing the right equipment for the required job only till its done.'
                url='rental-services-for-equipment'
                image={rental}
                />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Services
