import React from 'react'
import Contact from '../components/ContactUs'

const ManPower = () => {
  document.title ='Man Power - Services' ;
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Services</div>
      <div className="lg:flex gap-16 max-w-[1200px] lg:mx-auto mx-4 my-8">
        <Contact/>
        <div className='lg:w-[900px] lg:my-0 my-16'>
            <h3 className="text-3xl">Manpower Supply</h3>
            <p className="my-6 text-paragraph text-sm">
            We are leading providers of human resource solutions and employ in Pakistan. We offer a growing list of services related to our core competencies of Human Resources especially in Oil & Gas Sector.
            </p>
            <p className="my-6 text-paragraph text-sm">
            We are totally committed to a culture of excellence and unwavering focused on maximum customer satisfaction.
            </p>
            <p className="my-6 text-paragraph text-sm">
            KAMCON is one of the known organization engaged in providing Engineer Manpower Service. Our organization is very experience to fulfill the requirement of our clients. Widely appreciated by our customers, these services are provided by our experienced team members who are perfect in this field.
            </p>
            
            <p className="my-6 text-paragraph text-sm">
            Through the delivery of world-class services, KAMCON strives to add value to the activities of its customers and partners. This performance-driven approach is reflected in the variety of commercial models through which KAMCON Manpower supply leverages the group’s service capability.
            </p>
            
        </div>
      </div>
    </div>
  )
}

export default ManPower
