import React,{useState} from 'react'

import HeroSection from '../components/pageSections/HomePage/HeroSection'
import WhoWeAre from '../components/pageSections/HomePage/WhoWeAre'
import Services from '../components/pageSections/HomePage/Services'
import Projects from '../components/pageSections/HomePage/Projects'
import Message from '../components/pageSections/HomePage/Message'
import OurClients from '../components/pageSections/HomePage/OurClients'
import NewsEvents from '../components/pageSections/HomePage/NewsEvents'
const Home = () => {
  document.title ='Kamconeng - From Concept to Creation' ;

  return (
    <div>
      <HeroSection/>
      <WhoWeAre/>
      <Services/>
      <Projects/>
      <Message/>
      <OurClients/>
      <NewsEvents/>
    </div>
  )
}

export default Home
