import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

const SingleProject = () => {
  const { id } = useParams();
    const [data,setData] = useState([]);

  useEffect(() => {
    axios.get(`https://kamconeng.com/admin/api/project-single-api?id=${id}`)
      .then(response => {
        setData(response.data.slice(0,6));
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>
      {
      data.map((proj,index)=>{
        return(
          <div key={index} className='py-16'>
          <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Projects</div>
          <div className="my-9 max-w-[1200px] lg:mx-auto mx-4">
            <h1 className="text-3xl font-medium mb-9 text-center">{document.title = proj.project_title}</h1><hr />
            <div className="md:flex gap-6">
              <div className='my-8 md:w-[500px]'>
                <div><img className='w-[300px]' src={`https://kamconeng.com/admin/upload/${proj.image}`} alt="" /></div>
              </div>
              <div className='my-4 text-paragraph text-sm'>
                <div className='my-4'><strong>Scope</strong>
                <p className='font-medium text-dark'>{proj.scope}</p></div>
                <div className='my-4'><strong>PROJECT LOCATION:</strong>
                <p className='font-medium text-dark'>{proj.location}</p></div>
                <div className='my-4'><strong>PROJECT DURATION: </strong>
                <p className='font-medium text-dark'>{proj.duration}</p></div>
                <div className='my-4'><strong>PROJECT COST:</strong>
                <p className='font-medium text-dark'>{proj.cost}</p></div>
                <div className='my-4'><strong>CLIENT:</strong>
                <p className='font-medium text-dark'>{proj.client}</p></div>
              </div>
            </div>
          </div>
        </div>
        )
      })
      }
    
    </>
  )
}

export default SingleProject
