import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import SingleBlog from "./pages/SingleBlog";
import Company from "./pages/Company";
import News from "./pages/News";

import Footer from "./components/Footer";
import Services from "./pages/Services";
import Procurement from "./pages/Procurement";
import CivilConstruction from "./pages/CivilConstruction";
import MechanicalFab from "./pages/MechanicalFab";
import FlowLine from "./pages/FlowLine";
import PlantTurn from "./pages/PlantTurn";
import EIWork from "./pages/EIWork";
import ManPower from "./pages/ManPower";
import RentalServices from "./pages/RentalServices";
import Careers from "./pages/Careers";
import Contact from "./Contact";
import SingleProject from "./pages/SingleProject";
import Projects from "./pages/Projects";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/company" element={<Company/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/services/procurement" element={<Procurement/>}/>
        <Route path="/services/civil-construction" element={<CivilConstruction/>}/>
        <Route path="/services/mechanical-fabrication-erection" element={<MechanicalFab/>}/>
        <Route path="/services/flow-line" element={<FlowLine/>}/>
        <Route path="/services/e-i-work" element={<EIWork/>}/>
        <Route path="/services/plant-turnaround" element={<PlantTurn/>}/>
        <Route path="/services/manpower-supply" element={<ManPower/>}/>
        <Route path="/services/rental-services-for-equipment" element={<RentalServices/>}/>
        <Route path="/projects" element={<Projects/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/careers" element={<Careers/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/blog/:id/:tit" element={<SingleBlog/>}/>
        <Route path="/project/:id/:tit" element={<SingleProject/>}/>

      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
