import React from 'react'
// import 'react-slideshow-image/dist/styles.css';

import { Zoom,Fade,Slide } from 'react-slideshow-image';
import slider1 from '../../../assets/Clough1.png'
import slider2 from '../../../assets/Descon1.png'
import slider3 from '../../../assets/Engro1.png'
import slider4 from '../../../assets/Pepso1.png'
import slider5 from '../../../assets/Petronas1.png'
import slider6 from '../../../assets/ppl1.png'
import slider7 from '../../../assets/Shell1.png'

  
const OurClients = () => {

  return (
    <div className='bg-sectionsbg py-9'>
        <div className='max-w-[1200px] lg:mx-auto mx-4'>
            <h1 className="text-3xl text-center text-heading">We Love Our Clients</h1>
            <p className='text-center text-paragraph my-1'>Our clients come first at fifteen, and we are proud to work with some of the world's best known brands</p>
            <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-9 my-9 ">
                <img src={slider1} alt="" />
                <img src={slider2} alt="" />
                <img src={slider3} alt="" />
                <img src={slider4} alt="" />
                <img src={slider5} alt="" />
                <img src={slider6} alt="" />
                <img src={slider7} alt="" />
            </div>
        </div>
    </div>
  )
}

export default OurClients
