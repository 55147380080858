import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Projects = () => {
const [pdata,setpData] = useState([]);
  useEffect(() => {
    axios.get('https://kamconeng.com/admin/api/project-api')
      .then(response => {
        setpData(response.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, []);
  document.title = 'Projects';
  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Projects</div>
      <div className="max-w-[1200px] lg:mx-auto mx-4">
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-6">
          {
            pdata.map((proj,index)=>{
              return(
                <div key={index}>
                  <div className='overflow-hidden'><Link to={`/project/${proj.project_id}/${proj.project_title}`} ><img className='z-[1] relative duration-300 w-[400px] cursor-pointer hover:scale-110' src={`https://kamconeng.com/admin/upload/${proj.image}`} alt="" /></Link>
                </div>
                <div className="top-0 z-[9999] overflow-hidden"><Link to={`/project/${proj.project_id}/${proj.project_title}`}>{proj.project_title}</Link></div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Projects
