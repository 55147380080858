import React, { useEffect, useState } from 'react'
import 'react-slideshow-image/dist/styles.css';
import { Fade} from 'react-slideshow-image';
import slider1 from '../assets/Company/Slide-Careers-1.jpg'
import slider2 from '../assets/Company/Slide-Careers-2 (1).jpg'
import slider3 from '../assets/Company/Slide-Careers-2.jpg'
import slider4 from '../assets/Company/Slide-Careers-4.jpg'
import { Link } from 'react-router-dom';
import Card from '../components/Card';
import Positions from '../components/pageSections/HomePage/positions'
import axios from 'axios';




    const Careers = () => {

    const slideImages = [
        {
          url:slider1,
          
        },
        {
          url:slider2,
          
        },
        {
          url:slider3,
          
        },
        {
          url:slider4,
          
        },
      ];
    
      const divStyle = {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        height:'520px',
        backgroundSize:'cover',
        backgroundColor:'#000000'
      }

      
     
      document.title ='Careers' ;

      const [data,setData] = useState([]);
      useEffect(() => {
        axios.get('https://kamconeng.com/admin/api/positions-api.php')
        .then(response => {



      setData(response.data);
      })
      .catch(error => {
      // Handle error
      console.error('Error fetching data:', error);
      });
      }, []);
      const convertHtmlToText = (html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

  return (
    <div className='py-16'>
      <div className="py-16 text-center bg-slate-100 text-4xl text-second font-semibold uppercase">Careers</div>
      <div className='max-w-[1200px] lg:mx-auto mx-4'>
      <Fade>
        {slideImages.map((image,index)=>(
          <div key={index}>
            <div className='bg-cover' style={{...divStyle,backgroundImage:`url(${image.url})`}}>
              <div className='text-center flex flex-col justify-center items-center uppercase'>
                <span className='text-white font-bold md:text-[5rem] sm:text-[3rem] text-2xl w-auto md:w-[700px] my-2 sm:leading-[5rem] block text-center bg-black/60'>{image.caption}</span>
              </div>
            </div>
          </div>
        ))}
      </Fade>
      <div className='my-9'>
        <h3 className="text-3xl font-mediu text-heading ">Career Opportunities</h3>
        <p className="my-4 text-paragraph text-sm">KAMCON provides an absolutely learning and work friendly environment to the employees and also constantly monitors the performance levels of the workers for a greater team performance. The experts of KAMCON team inculcate the leadership skills and also identify the workers with various competence – based and management training sessions so that they perform with their best potential.</p>
        <p className="my-4 text-paragraph text-sm">The major aim of the organization is to maintain talent of every single individual. These are very helpful in the improvisation of the skills of all the individuals who will simultaneously implement their learnt knowledge and improved skills for their better performance and also to provoke the organizational growth at the same time.</p>
      </div>
      <hr />
      <div className='my-9'>
         
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4 my-6" >
          {
            data.map((positions)=>{
              return(
                <Card
                title={positions.position_title}
                desc={`${convertHtmlToText(positions.position_description.slice(0,100))}...`}
                image={`https://kamconeng.com/admin/upload/${positions.p_image}`}
                url={`/positions/${Positions.id}/${positions.position_title}`}
                time={positions.p_date.slice(0,10)}
                />
              )
            })
          }

            {/* <Card
            title=''
            image={newsimage3}
            url=''
            time='25 July'
            />
            <Card
            title='Successful Pre-Qualification'
            image={newsimage2}
            url=''
            time='3 December'
            /> */}
        </div>
        <hr />
        
       
        <div className='my-9'>
            <h1 className="text-4xl font-medium">How to Apply:</h1>
            <p className='mt-5 font-medium text-xl'>You can submit your resumes at <Link className='text-primary' to='mailto:hr@kamconeng.com'>hr@kamconeng.com</Link></p>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Careers
